import React from 'react'
import ReactDOM from 'react-dom'
import loadable from '@loadable/component'

const documentRoot = document.getElementById('root')
if (documentRoot) {
  const App = loadable(() => import('./App' /* webpackChunkName: "App" */))

  ReactDOM.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>,
    documentRoot,
  )
}

const cmsRoot = document.getElementById('cms_root')
if (cmsRoot) {
  const AppCms = loadable(() => import('./AppCms' /* webpackChunkName: "AppCms" */))

  ReactDOM.render(
    <React.StrictMode>
      <AppCms/>
    </React.StrictMode>,
    cmsRoot
  )
}
